import styled from "styled-components";
import { WHITE } from "../../constants/colors";

export const UtilsHelperStyle = styled.div`
.loader-wrapper{
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${WHITE};
  z-index: 1500;
  opacity: 0.7;
}
.loader{
  position: absolute;
  top: 50vh;
  left: 50vw;
}
`