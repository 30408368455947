export const getCategoryForm = () => {
  let categoryForm = [
    {
      label: 'Enter Category Name',
      name: 'categoryName',
      type: 'text',
      placeholder: 'Category Name',
      desc:'',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Category Description',
      name: 'categoryDesc',
      type: 'text',
      placeholder: 'Category Description',
      desc:'',
      defaultValue: '',
      regex: '',
      variant: 'outlined',
      width:12,
      multiline: true,
      rows: 4
    },
    {
      label: 'Upload Category picture',
      name: 'categoryImage',
      type: 'file',
      multiple: true,
      accept: "image/*",
      placeholder: 'Select File',
      defaultValue: '',
      required: true,
      variant: 'contained',
      width: 12
    }
  ]
  return categoryForm
}