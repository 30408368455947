import { useCallback, useEffect, useRef } from "react"
import Form from "../Form"
import { getAddressForm } from "./constants"

function Address(props) {
  const submitFormRef = useRef('')

  const formData = getAddressForm(props.defaultValues)
  
  const handleSubmit = useCallback((e)=>{
    const response = submitFormRef.current(e)
    if (response) {
      return response
    }
  },[])
  
  useEffect(()=>{
    props.getSubmitMethod(handleSubmit, 'ADDRESS')
  },[handleSubmit])

  const getSubmitMethod = useCallback((submitMethod) => {
    submitFormRef.current = submitMethod
  }, [])

  return (
    <>
      <Form
        formData={formData}
        getSubmitMethod={getSubmitMethod}
      />
    </>
  )
}

export default Address