import { fetchCategories, resetCategoriesState } from "../../../../app/actionReducers/categories";
import { fetchBrands, resetBrandsState } from "../../../../app/actionReducers/brands";
import { fetchUsers, resetUsersState } from "../../../../app/actionReducers/users";

export const getProductForm = ()=> {
  let prodForm =[
    {
      label: 'Enter Product Name',
      name: 'productName',
      type: 'text',
      placeholder: 'Product Name',
      desc:'',
      defaultValue: '',
      min: 3,
      max: 30,
      regex: '',
      required: true,
      variant: 'outlined'
    },
    {
      label: 'Enter Category',
      name: 'categoryId',
      type: 'autocomplete',
      placeholder: 'Category',
      desc:'',
      defaultValue: '',
      min: '',
      max: '',
      regex: '',
      required: true,
      variant: 'outlined',
      options: [],
      optionLabelKey: 'categoryName',
      dataFetchAction: fetchCategories,
      dataFetchResetAction: resetCategoriesState,
      dataFetchQueryName: 'categoryName',
      dataFetchStateName: 'categories'
    },
    {
      label: 'Enter Brand',
      name: 'brandId',
      type: 'autocomplete',
      placeholder: 'Brand',
      desc:'',
      defaultValue: '',
      min: '',
      max: '',
      regex: '',
      required: true,
      variant: 'outlined',
      options: [],
      optionLabelKey: 'brandName',
      dataFetchAction: fetchBrands,
      dataFetchResetAction: resetBrandsState,
      dataFetchQueryName: 'brandName',
      dataFetchStateName: 'brands'
    },
    {
      label: 'Enter Supplier',
      name: 'supplierId',
      type: 'autocomplete',
      placeholder: 'Supplier',
      desc:'',
      defaultValue: '',
      min: '',
      max: '',
      regex: '',
      required: true,
      variant: 'outlined',
      options: [],
      optionLabelKey: 'name',
      defaultFetchQueryParams: [{key:'role',value:'supplier'}],
      dataFetchAction: fetchUsers,
      dataFetchResetAction: resetUsersState,
      dataFetchQueryName: 'name',
      dataFetchStateName: 'users'
    },
    {
      label: 'Enter Product Desc',
      name: 'productDesc',
      type: 'text',
      placeholder: 'Product Desc',
      multiline: true,
      rows: 5,
      desc: '',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
      width: 12
    },
    {
      label: 'Enter Quantity/Unit',
      name: 'quantityPerUnit',
      type: 'number',
      placeholder: '',
      desc:'',
      defaultValue: '',
      min: 1,
      max: '',
      regex: '',
      required: true,
      variant: 'outlined',
    },
    {
      label: 'Enter Unit Price',
      name: 'unitPrice',
      type: 'amount',
      placeholder: '',
      desc:'',
      defaultValue: '',
      min: 1,
      max: '',
      regex: '',
      required: true,
      currency: 'Rs',
      variant: 'outlined',
    },
    {
      label: 'Enter Product Stock',
      name: 'productStock',
      type: 'number',
      placeholder: '',
      desc:'',
      defaultValue: '',
      min: 1,
      max: '',
      regex: '',
      required: true,
      variant: 'outlined',
    },
    {
      label: 'Enter Weight',
      name: 'weight',
      type: 'number',
      placeholder: '',
      desc:'(In grams)',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
    },
    {
      label: 'Enter Height',
      name: 'height',
      type: 'number',
      placeholder: '',
      desc:'(In cm)',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
    },
    {
      label: 'Enter Bredth',
      name: 'bredth',
      type: 'number',
      placeholder: '',
      desc:'(In cm)',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
    },
    {
      label: 'Enter Length',
      name: 'length',
      type: 'number',
      placeholder: '',
      desc:'(In cm)',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
    },
    {
      label: 'Upload Product picture',
      name: 'productImage',
      type: 'file',
      multiple: true,
      accept: "image/*",
      placeholder: 'Select File',
      defaultValue: '',
      required: true,
      variant: 'contained',
      width: 12
    }
  ]
  return prodForm
}