import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {parseError} from '../../../utils/errorParser'
import { getCookie } from '../../../utils/cookiesUtil'

export const fetchSuppliers = createAsyncThunk('fetchSuppliers',async (payload)=>{
  let url = `${process.env.REACT_APP_HOSTNAME}/get-suppliers`
  if(payload.queryParams){
    url = `${url}${payload.queryParams}`
  }
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    throw new Error("some error")
  }
})

export const fetchSupplierDetails = createAsyncThunk('/fetchSupplierDetails',async (payload)=>{
  const url = `${process.env.REACT_APP_HOSTNAME}/get-supplier-detail/${payload.supplierId}`
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))  }
})

export const updateSupplierDetail = createAsyncThunk('/updateSupplierDetail',async (payload)=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/update-supplier-detail/${payload.supplierId}`,{
    method: 'POST',
    headers: {
      'Content-type':'application/json',
      'authorization': `Bearer ${getCookie('authToken')}`
    },
    body: JSON.stringify(payload.body)
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const supplierSlice = createSlice({
  name:'users',
  initialState:{
    suppliers:{
      data: [],
      status: 'idle',
      error: null
    },
    supplierDetail:{
      data: {},
      status: 'idle',
      error: null
    },
    updateSupplierDetail:{
      data: {},
      status: 'idle',
      error: null
    }
  },
  reducers:{
    resetSuppliersState: (state,action)=>{
      state[action.payload]['status'] = 'idle'
    }
  },  
  extraReducers: {
    [fetchSuppliers.pending]: (state, action) => {
      state.suppliers.status = 'loading'
    },
    [fetchSuppliers.fulfilled]: (state, action) => {
      state.suppliers.status = 'succeeded'
      state.suppliers.data = action.payload
    },
    [fetchSuppliers.rejected]: (state, action) => {
      state.suppliers.status = 'failed'
      state.suppliers.error = action.error.message
    },
    [fetchSupplierDetails.pending]: (state, action) => {
      state.supplierDetail.status = 'loading'
    },
    [fetchSupplierDetails.fulfilled]: (state, action) => {
      state.supplierDetail.status = 'succeeded'
      state.supplierDetail.data = action.payload
    },
    [fetchSupplierDetails.rejected]: (state, action) => {
      state.supplierDetail.status = 'failed'
      state.supplierDetail.error = action.error.message
    },
    [updateSupplierDetail.pending]: (state, action) => {
      state.updateSupplierDetail.status = 'loading'
    },
    [updateSupplierDetail.fulfilled]: (state, action) => {
      state.updateSupplierDetail.status = 'succeeded'
      state.updateSupplierDetail.data = action.payload
    },
    [updateSupplierDetail.rejected]: (state, action) => {
      state.updateSupplierDetail.status = 'failed'
      state.updateSupplierDetail.error = action.error.message
    }
  }
})


export const { resetSuppliersState } = supplierSlice.actions
export default supplierSlice.reducer