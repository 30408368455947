import { LoginStyle } from './index.style'
import { Grid, Paper, Box, Button, Typography } from '@mui/material'
import Form from '../../components/Form'
import { getLoginForm } from './constants'
import { useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { login, resetAuthState } from '../../app/actionReducers/auth'
import { useEffect } from 'react'
import { showSnackbar, showLoader } from '../../app/actionReducers/utilsHelper'
import { useDispatch, useSelector } from 'react-redux'
import { setCookie } from '../../utils/cookiesUtil'

function Login(){
  const dispatch = useDispatch()
  const history = useHistory()
  const loginData = useSelector(state => state.auth.login)

  const formData = getLoginForm()

  const submitFormRef = useRef('')

  const handleSubmit = useCallback(async (e)=>{
    const response = await submitFormRef.current(e)
    if(response){
      dispatch(showLoader(true))
      dispatch(login(response))
    }
  },[dispatch])

  useEffect(() => {
    if (loginData.status === 'failed') {
      dispatch(showSnackbar({
        message: loginData.error,
        severity: 'error'
      }))
    }
    if (loginData.status === 'failed' || loginData.status === 'succeeded') {
      dispatch(showLoader(false))
      dispatch(resetAuthState('login'))
    }
    if(loginData.status === 'succeeded'){
      setCookie('authToken',loginData.data.token)
      history.push('/product-requests')
    }
  }, [loginData, dispatch, 
    history
  ])

  const getSubmitMethod = useCallback((submitMethod)=>{
    submitFormRef.current = submitMethod
  },[])

  return(
    <LoginStyle>
      <Grid className="bg-gray h-100" container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={3} >
          <Box maxHeight="50%" component={Paper} p={3} boxShadow={5}>
            <Typography variant="h5" gutterBottom align="center">Login</Typography>
            <Typography color="textSecondary" variant="subtitle2" gutterBottom align="center">Continue to Control Panel</Typography>
            <Form
              formData = {formData}
              getSubmitMethod = {getSubmitMethod}
            />
            <Box textAlign="right" mt={2}>
              <Button 
                mt={1}
                variant="contained" 
                color="secondary"
                size="large"
                onClick={handleSubmit}
              > 
                Login 
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </LoginStyle>
  )
}

export default Login