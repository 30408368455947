import styled from 'styled-components'
import { BLUE, WHITE } from '../../constants/colors'

export const DataGridStyle = styled.div`
  .table-head-container{
    background-color: ${BLUE};
  }
  .table-head{
    color: ${WHITE};
    font-size: 120%;
  }
`