import { TextField, MenuItem, Grid, InputLabel, Typography } from "@mui/material"
import { useState, useCallback } from 'react'
import { getNumbersBetween, getMonthsBetween } from "../../../../constants/common"
import { DatepickerStyle } from './index.style'

function DatePicker(props) {
  const { label, value = '', variant,
    required, error, helperText, onChange, min, max
  } = props
  const [day, setDay] = useState(value.substr(0, 2) || '')
  const [month, setMonth] = useState(value.substr(3, 2) || '')
  const [year, setYear] = useState(value.substr(6, 4) || '')

  const maxDay = max.substr(0, 2) || 31
  const maxMonth = max.substr(3, 2) || 12
  const maxYear = max.substr(6, 4) || 2030

  const minDay = min.substr(0, 2) || 1
  const minMonth = min.substr(3, 2) || 1
  const minYear = min.substr(6, 4) || 1990


  const handleChange = (val, type) => {
    let tempDay = day
    let tempMonth = month
    let tempYear = year

    switch (type) {
      case 'day':
        tempDay = val
        setDay(val)
        break;
      case 'month':
        tempMonth = val
        setMonth(val)
        break;
      case 'year':
        tempYear = val
        setYear(val)
        break;
      default:
        break
    }
    onChange(`${tempDay}-${tempMonth}-${tempYear}`)
  }

  const renderDays = useCallback(() => {
    return getNumbersBetween(minDay, maxDay, 2).map((i) => {
      return (
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      )
    })
  }, [minDay, maxDay])

  const renderMonths = useCallback(() => {
    return getMonthsBetween(minMonth, maxMonth).map((month) => {
      return (
        <MenuItem key={month.key} value={month.key}>
          {month.value}
        </MenuItem>
      )
    })
  }, [minMonth, maxMonth])

  const renderYears = useCallback(() => {
    return getNumbersBetween(minYear, maxYear).map((year) => {
      return (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      )
    })
  }, [minYear, maxYear])

  return (
    <DatepickerStyle error={error}>
      <InputLabel required={required} className="label"> {label} </InputLabel>
      <Grid container space={1}>
        <Grid item xs={4}>
          <TextField
            select
            label={'day'}
            value={day}
            onChange={(e) => handleChange(e.target.value, 'day')}
            variant={variant}
            fullWidth
            size="small"
            required={required}
            error={Boolean(error)}
          >
            {
              renderDays()
            }
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            label={'Month'}
            value={month}
            onChange={(e) => handleChange(e.target.value, 'month')}
            variant={variant}
            fullWidth
            size="small"
            required={required}
            error={Boolean(error)}
          >
            {
              renderMonths()
            }
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            label={'Year'}
            value={year}
            onChange={(e) => handleChange(e.target.value, 'year')}
            variant={variant}
            fullWidth
            size="small"
            required={required}
            error={Boolean(error)}
          >
            {
              renderYears()
            }
          </TextField>
        </Grid>
      </Grid>
      {
        error && <Typography color="error" fontSize="12px" variant="subtitle2">
        {helperText}
      </Typography>
      }
    </DatepickerStyle>
  )
}

export default DatePicker