export const parseError = (err)=>{
  let error = ''
  switch(typeof err){
    case 'object':
      Object.keys(err).forEach((errorItem,i)=>{
        if(i !== 0){
          error = `${error} & ${err[errorItem]}`
        }else{
          error = `${err[errorItem]}`
        }
      })
      break
    case 'string':
      error = err
      break
    default:
      error = 'Some error Occured'
  }
  return error
}