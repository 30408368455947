import { configureStore } from '@reduxjs/toolkit';
import productsReducer from './actionReducers/products'
import productRequestsReducer from './actionReducers/productRequests'
import usersReducer from './actionReducers/users'
import suppliersReducer from './actionReducers/suppliers'
import otpsReducer from './actionReducers/otps'
import brandsReducer from './actionReducers/brands'
import categoriesReducer from './actionReducers/categories'
import utilsReducer from './actionReducers/utilsHelper'
import authReducer from './actionReducers/auth'

export default configureStore({
  reducer: {
    products: productsReducer,
    productRequests: productRequestsReducer,
    users: usersReducer,
    suppliers: suppliersReducer,
    otps: otpsReducer,
    utils: utilsReducer,
    auth: authReducer,
    brands: brandsReducer,
    categories: categoriesReducer
  },
});
