import {useEffect, useMemo, useCallback} from 'react'
import { fetchUsers, resetUsersState } from '../../app/actionReducers/users'
import {useDispatch, useSelector} from 'react-redux'
import Container from '../../components/Container'
import DataGrid from '../../components/DataGrid'
import { useHistory } from "react-router-dom";
import { showSnackbar,showLoader } from '../../app/actionReducers/utilsHelper'

function Users(){
  const users = useSelector(state => state.users.users)
  const history = useHistory()
  const dispatch = useDispatch()

  const columns = useMemo(()=>[
    {
      key: 'userId',
      text: 'User Id',
      enableSearch: true
    },
    {
      key: 'phone',
      text: 'Phone No',
      enableSearch: true
    },
    {
      key: 'access',
      text: 'Access',
      enableSearch: false
    },
    {
      key: 'status',
      text: 'Status',
      enableSearch: true
    }
  ],[])

  useEffect(()=>{
    if (users.status === 'failed' || users.status === 'succeeded') {
      if(users.status === 'failed'){
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: users.error,
          severity: 'error'
        }))
      }
      dispatch(resetUsersState('users'))
    }
  }, [users, dispatch])

  // const handleControls = useCallback((control,data)=>{
  //   switch(control){
  //     case 'view':
  //       history.push('/')
  //       break
  //     default:
  //   }
  // },[history])

  // const handleAddUser = useCallback(()=>{
  //   history.push('/add-user')
  // },[history])

  //this function is basically a data call for the GRID
  const getUsersData = useCallback((queryParams)=>{
    dispatch(fetchUsers({
      queryParams: queryParams
    }))
  },[dispatch])

  dispatch(showLoader(users.status==='loading'))

  return(
    <Container>
      <DataGrid
        columns={columns}
        data={users.data.data}
        fetchData = {getUsersData}
        enablePagination={true}
      />
    </Container>
  )
}

export default Users