import {useEffect, useMemo, useCallback} from 'react'
import { fetchCategories, resetCategoriesState } from '../../app/actionReducers/categories'
import {useDispatch, useSelector} from 'react-redux'
import Container from '../../components/Container'
import DataGrid from '../../components/DataGrid'
import Button from '@mui/material/Button'
import { useHistory } from "react-router-dom";
import { showSnackbar,showLoader } from '../../app/actionReducers/utilsHelper'

function Categories(){
  const categories = useSelector(state => state.categories.categories)
  const history = useHistory()
  const dispatch = useDispatch()

  const columns = useMemo(()=>[
    {
      key: 'categoryId',
      text: 'Category Id',
      enableSearch: true
    },
    {
      key: 'categoryName',
      text: 'Category Name',
      enableSearch: true
    },
    {
      key: 'categoryDesc',
      text: 'Category Description',
      enableSearch: true
    },
    {
      key: 'status',
      text: 'Status',
      enableSearch: true
    }
  ],[])

  useEffect(()=>{
    if (categories.status === 'failed' || categories.status === 'succeeded') {
      if(categories.status === 'failed'){
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: categories.error,
          severity: 'error'
        }))
      }
      dispatch(resetCategoriesState('categories'))
    }
  }, [categories, dispatch])

  const handleControls = useCallback((control,data)=>{
    switch(control){
      case 'view':
        history.push('/')
        break
      default:
    }
  },[history])

  const handleAddCategory = useCallback(()=>{
    history.push('/add-category')
  },[history])

  //this function is basically a data call for the GRID
  const getCategoriesData = useCallback((queryParams)=>{
    dispatch(fetchCategories({
      queryParams: queryParams
    }))
  },[dispatch])

  dispatch(showLoader(categories.status==='loading'))

  return(
    <Container>
      <Button 
        variant="contained" 
        color="secondary"
        onClick={handleAddCategory}
      >
        Add New Category
      </Button>
      <DataGrid
        columns={columns}
        data={categories.data.data}
        fetchData = {getCategoriesData}
        enablePagination={true}
        controls={{view:true}}
        onControlClick = {handleControls}
      />
    </Container>
  )
}

export default Categories