export const getBrandForm = () => {
  let brandForm = [
    {
      label: 'Enter Brand Name',
      name: 'brandName',
      type: 'text',
      placeholder: 'Brand Name',
      desc:'',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Brand Description',
      name: 'brandDesc',
      type: 'text',
      placeholder: 'Brand Description',
      desc:'',
      defaultValue: '',
      regex: '',
      variant: 'outlined',
      width:12,
      multiline: true,
      rows: 4
    },
    {
      label: 'Upload Brand picture',
      name: 'brandImage',
      type: 'file',
      multiple: true,
      accept: "image/*",
      placeholder: 'Select File',
      defaultValue: '',
      required: true,
      variant: 'contained',
      width: 12
    }
  ]
  return brandForm
}