import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import Products from '../pages/products'
import ProductRequests from '../pages/ProductRequests'
import Login from '../pages/login'
import AddProduct from '../pages/products/AddProduct'
import AddUser from '../pages/users/AddUser'
import AddBrand from '../pages/brands/AddBrand'
import AddCategory from '../pages/categories/AddCategory'
import Users from '../pages/users'
import Suppliers from '../pages/suppliers'
import EditSupplier from '../pages/suppliers/editSupplier/index'
import Brands from '../pages/brands'
import Categories from '../pages/categories'
import get from '../methods/authGet'
import { useDispatch } from "react-redux"
import { setIsLoggedIn } from "../app/actionReducers/auth"
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RestrictedRoute from './RestrictedRoute'
import ProductRequestDetail from "../pages/ProductRequestDetail"
import Otps from "../pages/otps"

function Routes(){
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)

  useEffect(()=>{
    async function verifySession(){
      try{
        const response = await get({url: `${process.env.REACT_APP_HOSTNAME}/verify-session`}) 
        dispatch(setIsLoggedIn(true))
      }catch(err){
        dispatch(setIsLoggedIn(false))
      }
      setIsLoading(false)
    }
    verifySession()
  },[dispatch])

  if(isLoading){
    return(
      <div>
        Loading...
      </div>
    )
  }else{
    return(
      <Router>
        <Switch>
          <Route exact 
            path="/" 
            render={() => {
              if(isLoggedIn){
                return <Redirect to="/product-requests" />
              }else{
                return <Redirect to="/login" />
              }
            }} 
          />
          <Route exact path="/login">
            <Login/>
          </Route>
          <RestrictedRoute exact path='/product-request-detail/:productReqId' component={ProductRequestDetail} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/product-requests' component={ProductRequests} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/products' component={Products} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/otps' component={Otps} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/add-product' component={AddProduct} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/users' component={Users} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/suppliers' component={Suppliers} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/supplier/edit/:supplierId' component={EditSupplier} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/add-user' component={AddUser} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/add-brand' component={AddBrand} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/brands' component={Brands} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/categories' component={Categories} isLoggedIn={isLoggedIn} />
          <RestrictedRoute exact path='/add-category' component={AddCategory} isLoggedIn={isLoggedIn} />
        </Switch>
      </Router>
    )
  }
}

export default Routes