import {useEffect, useMemo, useCallback} from 'react'
import { fetchProducts, resetProductsState } from '../../app/actionReducers/products'
import {useDispatch, useSelector} from 'react-redux'
import Container from '../../components/Container'
import DataGrid from '../../components/DataGrid'
import Button from '@mui/material/Button'
import { useHistory } from "react-router-dom";
import { showSnackbar,showLoader } from '../../app/actionReducers/utilsHelper'

function Products(){
  const products = useSelector(state => state.products.products)
  const history = useHistory()
  const dispatch = useDispatch()

  const columns = useMemo(()=>[
    {
      key: 'productId',
      text: 'Product Id',
      enableSearch: true
    },
    {
      key: 'productName',
      text: 'Product Name',
      enableSearch: true
    },
    {
      key: 'supplierId',
      text: 'Supplier Id',
      enableSearch: true
    },
    {
      key: 'categoryId',
      text: 'Category Id',
      enableSearch: true
    },
    {
      key: 'quantityPerUnit',
      text: 'Quantity/Unit',
      enableSearch: true
    },
    {
      key: 'unitPrice',
      text: 'Unit Price',
      enableSearch: true
    },
    {
      key: 'productStock',
      text: 'Product Stock',
      enableSearch: true
    },
    {
      key: 'weight',
      text: 'Weight',
      enableSearch: true
    },
    {
      key: 'brandId',
      text: 'Brand Id',
      enableSearch: true
    },
    {
      key: 'status',
      text: 'Status',
      enableSearch: true
    }
  ],[])

  useEffect(()=>{
    if (products.status === 'failed' || products.status === 'succeeded') {
      if(products.status === 'failed'){
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: products.error,
          severity: 'error'
        }))
      }
      dispatch(resetProductsState('products'))
    }
  }, [products, dispatch])

  const handleControls = useCallback((control,data)=>{
    switch(control){
      case 'view':
        history.push('/')
        break
      default:
    }
  },[history])

  const handleAddProduct = useCallback(()=>{
    history.push('/add-product')
  },[history])

  //this function is basically a data call for the GRID
  const getProductsData = useCallback((queryParams)=>{
    dispatch(fetchProducts({queryParams:queryParams}))
  },[dispatch])

  dispatch(showLoader(products.status==='loading'))

  return(
    <Container>
      <Button 
        variant="contained" 
        color="secondary"
        onClick={handleAddProduct}
      >
        Add New Product
      </Button>
      <DataGrid
        columns={columns}
        data={products.data.data}
        fetchData = {getProductsData}
        enablePagination={true}
        controls={{view:true}}
        onControlClick = {handleControls}
      />
    </Container>
  )
}

export default Products