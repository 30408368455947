import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {parseError} from '../../../utils/errorParser'
import { getCookie, removeCookie } from '../../../utils/cookiesUtil'

export const login = createAsyncThunk('/login',async (payload)=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/login`,{
    method: 'POST',
    headers: {
      'Content-type':'application/json'
    },
    body: JSON.stringify(payload)
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const logout = createAsyncThunk('/logout',async ()=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/logout`,{
    method: 'POST',
    headers: {
      'Content-type':'application/json',
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const authSlice = createSlice({
  name:'auth',
  initialState:{
    login:{
      data: {},
      status: 'idle',
      error: null
    },
    logout:{
      data: {},
      status: 'idle',
      error: null
    },
    isLoggedIn: false
  },
  reducers:{
    setIsLoggedIn: (state, action)=>{
      state.isLoggedIn = action.payload
    },
    resetAuthState: (state,action)=>{
      state[action.payload]['status'] = 'idle'
    }
  },  
  extraReducers: {
    [login.pending]: (state, action) => {
      state.login.status = 'loading'
    },
    [login.fulfilled]: (state, action) => {
      state.login.status = 'succeeded'
      state.isLoggedIn = true
      state.login.data = action.payload
    },
    [login.rejected]: (state, action) => {
      state.login.status = 'failed'
      state.login.error = action.error.message
    },
    [logout.pending]: (state, action) => {
      state.logout.status = 'loading'
    },
    [logout.fulfilled]: (state, action) => {
      state.logout.status = 'succeeded'
      state.isLoggedIn = false
      state.logout.data = action.payload
      removeCookie('authToken')
    },
    [logout.rejected]: (state, action) => {
      state.logout.status = 'failed'
      state.logout.error = action.error.message
    }
  }
})

export const { setIsLoggedIn, resetAuthState } = authSlice.actions
export default authSlice.reducer