import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { getCookie } from '../../../utils/cookiesUtil'
import { parseError } from '../../../utils/errorParser'

export const fetchProducts = createAsyncThunk('products/fetchProducts',async (payload)=>{
  let url = `${process.env.REACT_APP_HOSTNAME}/product/get-products`
  if(payload.queryParams){
    url = `${url}${payload.queryParams}`
  }
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    throw new Error("some error")
  }
})

export const addProduct = createAsyncThunk('/products/addProduct',async (payload)=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/product/add-product`,{
    method: 'POST',
    headers: {
      'Content-type':'application/json',
      'authorization': `Bearer ${getCookie('authToken')}`
    },
    body: JSON.stringify(payload)
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const fetchProductDetail = createAsyncThunk('/products/fetchProductDetail',async ()=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/get-product-detail`)
  return response.data
})

export const productsSlice = createSlice({
  name:'products',
  initialState:{
    products:{
      data: [],
      status: 'idle',
      error: null
    },
    productDetail:{
      data: {},
      status: 'idle',
      error: null
    },
    addProduct:{
      data: {},
      status: 'idle',
      error: null
    }
  },
  reducers:{
    resetProductsState: (state,action)=>{
      state[action.payload]['status'] = 'idle'
    }
  },  
  extraReducers: {
    [fetchProducts.pending]: (state, action) => {
      state.products.status = 'loading'
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.products.status = 'succeeded'
      state.products.data = action.payload
    },
    [fetchProducts.rejected]: (state, action) => {
      state.products.status = 'failed'
      state.products.error = action.error.message
    },
    [fetchProductDetail.pending]: (state, action) => {
      state.productDetail.status = 'loading'
    },
    [fetchProductDetail.fulfilled]: (state, action) => {
      state.productDetail.status = 'succeeded'
      state.productDetail.data = action.payload
    },
    [fetchProductDetail.rejected]: (state, action) => {
      state.productDetail.status = 'failed'
      state.productDetail.error = action.error.message
    },
    [addProduct.pending]: (state, action) => {
      state.addProduct.status = 'loading'
    },
    [addProduct.fulfilled]: (state, action) => {
      state.addProduct.status = 'succeeded'
      state.addProduct.data = action.payload
    },
    [addProduct.rejected]: (state, action) => {
      state.addProduct.status = 'failed'
      state.addProduct.error = action.error.message
    }
  }
})

export const { resetProductsState } = productsSlice.actions

export default productsSlice.reducer