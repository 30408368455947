import { useEffect, useMemo, useCallback } from 'react'
import { fetchProductRequests, resetProductRequestsState } from '../../app/actionReducers/productRequests'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../../components/Container'
import DataGrid from '../../components/DataGrid'
import { useHistory } from "react-router-dom";
import { showSnackbar, showLoader } from '../../app/actionReducers/utilsHelper'

function ProductRequests() {
  const productRequests = useSelector(state =>
    state.productRequests.productRequests
  )
  const history = useHistory()
  const dispatch = useDispatch()

  const columns = useMemo(() => [
    {
      key: 'productReqId',
      text: 'Product Req Id',
      enableSearch: true
    },
    {
      key: 'productName',
      text: 'Product Name',
      enableSearch: true
    },
    {
      key: 'supplierId',
      text: 'Supplier Id',
      enableSearch: true
    },
    {
      key: 'categoryId',
      text: 'Category Id',
      enableSearch: true
    },
    {
      key: 'brandId',
      text: 'Brand Id',
      enableSearch: true
    },
    {
      key: 'status',
      text: 'Status',
      enableSearch: true
    }
  ], [])

  useEffect(() => {
    if (productRequests.status === 'failed' || productRequests.status === 'succeeded') {
      if (productRequests.status === 'failed') {
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: productRequests.error,
          severity: 'error'
        }))
      }
      dispatch(resetProductRequestsState('productRequests'))
    }
  }, [productRequests, dispatch])

  const handleControls = useCallback((control, data) => {
    switch (control) {
      case 'view':
        history.push(`/product-request-detail/${data.productReqId}`)
        break
      default:
    }
  }, [history])

  //this function is basically a data call for the GRID
  const getProductRequestsData = useCallback((queryParams) => {
    dispatch(fetchProductRequests({ queryParams: queryParams }))
  }, [dispatch])

  dispatch(showLoader(productRequests.status === 'loading'))

  return (
    <Container>
      <DataGrid
        columns={columns}
        data={productRequests.data.data}
        fetchData={getProductRequestsData}
        enablePagination={true}
        controls={{ view: true }}
        onControlClick={handleControls}
      />
    </Container>
  )
}

export default ProductRequests