import { Route, Redirect } from 'react-router-dom'

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={props => {
        return isLoggedIn ? (<Component {...props} />)
          : (
            <Redirect
              to={{
                pathname: '/login'
              }}
            />
          )
      }}
    />
  )
}

export default RestrictedRoute
