import { useEffect, useMemo, useCallback } from 'react'
import { fetchOtps, resetOtpsState } from '../../app/actionReducers/otps'
import { useDispatch, useSelector } from 'react-redux'
import Container from '../../components/Container'
import DataGrid from '../../components/DataGrid'
import { showSnackbar, showLoader } from '../../app/actionReducers/utilsHelper'
import { CustomText } from 'common-utils/out/ui/index'

function Otps() {
  const otps = useSelector(state => state.otps.otps)
  const dispatch = useDispatch()

  const columns = useMemo(() => [
    {
      key: 'id',
      text: 'Id',
      enableSearch: true
    },
    {
      key: 'phone',
      text: 'Phone',
      enableSearch: true
    },
    {
      key: 'otp',
      text: 'Otp',
      enableSearch: true
    },
    {
      key: 'count',
      text: 'Count',
      enableSearch: true
    },
    {
      key: 'type',
      text: 'Type',
      enableSearch: true
    },
    {
      key: 'data',
      text: 'Data',
      enableSearch: true,
      render: (val) => {
        return (<CustomText overflowLength={40}>
          {val}
        </CustomText>
        )
      }
    },
    {
      key: 'response',
      text: 'Response',
      enableSearch: false,
      render: (val) => {
        return (<CustomText overflowLength={30}>
          {val}
        </CustomText>
        )
      }
    },
    {
      key: 'verified',
      text: 'Verified',
      enableSearch: true
    },
    {
      key: 'addedAt',
      text: 'Added At',
      enableSearch: true
    },
    {
      key: 'updatedAt',
      text: 'updated At',
      enableSearch: true
    }
  ], [])

  useEffect(() => {
    if (otps.status === 'failed' || otps.status === 'succeeded') {
      if (otps.status === 'failed') {
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: otps.error,
          severity: 'error'
        }))
      }
      dispatch(resetOtpsState('otps'))
    }
  }, [otps, dispatch])

  //this function is basically a data call for the GRID
  const getOtpsData = useCallback((queryParams) => {
    dispatch(fetchOtps({
      queryParams: queryParams
    }))
  }, [dispatch])

  dispatch(showLoader(otps.status === 'loading'))

  return (
    <Container>
      <DataGrid
        columns={columns}
        data={otps.data.data}
        fetchData={getOtpsData}
        enablePagination={true}
      />
    </Container>
  )
}

export default Otps