import { CustomText, Row, ShadowContainer } from "common-utils";
import Container from "../../../components/Container";
import Form from "../../../components/Form";
import { getSupplierForm } from "./constants";
import { useCallback, useEffect, useRef, useState } from "react";
import Address from "../../../components/Address";
import { Button } from "@mui/material";
import { colorPallet } from "common-utils/constants/colors";
import { EditSupplierStyle } from "./index.style";
import {
    fetchSupplierDetails,
    resetSuppliersState,
    updateSupplierDetail
} from "../../../app/actionReducers/suppliers";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import { showLoader, showSnackbar } from "../../../app/actionReducers/utilsHelper";

function EditSupplier() {
    const submitFormRef = useRef("");
    const submitAddressRef = useRef("");
    const [supplierData, setSupplierData] = useState()

    const { supplierId } = useParams();
    const dispatch = useDispatch();

    const supplierDetail = useSelector((state) => {
        return state.suppliers.supplierDetail;
    });
    const updateSupplierDetailData = useSelector((state) => {
        return state.suppliers.updateSupplierDetail;
    });

    const getSubmitMethod = useCallback((submitMethod, methodType) => {
        if (methodType === "ADDRESS") {
            submitAddressRef.current = submitMethod;
        } else {
            submitFormRef.current = submitMethod;
        }
    }, []);

    useEffect(() => {
        dispatch(fetchSupplierDetails({ supplierId }));
    }, []);

    useEffect(() => {
        if (
            supplierDetail?.status === "failed" ||
            supplierDetail?.status === "succeeded"
        ) {
            if (supplierDetail.status === "failed") {
                dispatch(
                    showSnackbar({
                        message: supplierDetail.error,
                        severity: "error",
                    })
                );
            }else if(supplierDetail.status === "succeeded"){
                setSupplierData(supplierDetail?.data?.data)
            }
            dispatch(showLoader(false));
            dispatch(resetSuppliersState("supplierDetail"));
        }
        if (
            updateSupplierDetailData?.status === "failed" ||
            updateSupplierDetailData?.status === "succeeded"
        ) {
            if (updateSupplierDetailData.status === "failed") {
                dispatch(
                    showSnackbar({
                        message: updateSupplierDetailData.error,
                        severity: "error",
                    })
                );
            }else{
                dispatch(
                    showSnackbar({
                        message: "Supplier Data Updated",
                        severity: "success",
                    })
                );
            }
            dispatch(showLoader(false));
            dispatch(resetSuppliersState("updateSupplierDetail"));
        }
    }, [supplierDetail,updateSupplierDetailData]);

    const handleSubmit = useCallback(async (e) => {
        const responseForm = submitFormRef.current(e)
        const addressResponseForm = submitAddressRef.current(e)
        if (responseForm && addressResponseForm) {
            dispatch(
                updateSupplierDetail({
                    supplierId,
                    body: {
                        ...responseForm,
                        address: {
                            ...addressResponseForm,
                            addressId: supplierData.addressId,
                        },
                    },
                })
            );
        }
    }, [supplierData]);

    const formData = getSupplierForm(supplierData);

    return (
        <Container bgColor={colorPallet.lightGrey}>
            <EditSupplierStyle>
                <Row justify="center">
                    <Row justify="center" width="50%">
                        <div>
                            <ShadowContainer
                                title={
                                    <CustomText
                                        paddingTop="10px"
                                        marginBottom="10px"
                                        textAlign="center"
                                        fontSize="m2"
                                    >
                                        Edit Supplier
                                    </CustomText>
                                }
                            >
                                {supplierData && (
                                    <Form
                                        formData={formData}
                                        getSubmitMethod={getSubmitMethod}
                                    />
                                )}
                                <br />
                                <ShadowContainer
                                    title={
                                        <CustomText
                                            paddingTop="10px"
                                            marginBottom="10px"
                                            textAlign="center"
                                            fontSize="s3"
                                        >
                                            Address
                                        </CustomText>
                                    }
                                >
                                    {supplierData && (
                                        <Address
                                            defaultValues={supplierData.address}
                                            getSubmitMethod={getSubmitMethod}
                                        />
                                    )}
                                </ShadowContainer>
                            </ShadowContainer>

                            <br />
                            <Row justify="center">
                                <Button
                                    variant="contained"
                                    size="large"
                                    gutterTop
                                    color="secondary"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </Row>
                        </div>
                    </Row>
                </Row>
            </EditSupplierStyle>
        </Container>
    );
}

export default EditSupplier;
