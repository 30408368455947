import {useEffect, useMemo, useCallback} from 'react'
import { fetchBrands, resetBrandsState } from '../../app/actionReducers/brands'
import {useDispatch, useSelector} from 'react-redux'
import Container from '../../components/Container'
import DataGrid from '../../components/DataGrid'
import Button from '@mui/material/Button'
import { useHistory } from "react-router-dom";
import { showSnackbar,showLoader } from '../../app/actionReducers/utilsHelper'

function Brands(){
  const brands = useSelector(state => state.brands.brands)
  const history = useHistory()
  const dispatch = useDispatch()

  const columns = useMemo(()=>[
    {
      key: 'brandId',
      text: 'Brand Id',
      enableSearch: true
    },
    {
      key: 'brandName',
      text: 'Brand Name',
      enableSearch: true
    },
    {
      key: 'brandDesc',
      text: 'Brand Description',
      enableSearch: true
    },
    {
      key: 'status',
      text: 'Status',
      enableSearch: true
    }
  ],[])

  useEffect(()=>{
    if (brands.status === 'failed' || brands.status === 'succeeded') {
      if(brands.status === 'failed'){
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: brands.error,
          severity: 'error'
        }))
      }
      dispatch(resetBrandsState('brands'))
    }
  }, [brands, dispatch])

  const handleControls = useCallback((control,data)=>{
    switch(control){
      case 'view':
        history.push('/')
        break
      default:
    }
  },[history])

  const handleAddBrand = useCallback(()=>{
    history.push('/add-brand')
  },[history])

  //this function is basically a data call for the GRID
  const getBrandsData = useCallback((queryParams)=>{
    dispatch(fetchBrands({
      queryParams: queryParams
    }))
  },[dispatch])

  dispatch(showLoader(brands.status==='loading'))

  return(
    <Container>
      <Button 
        variant="contained" 
        color="secondary"
        onClick={handleAddBrand}
      >
        Add New Brand
      </Button>
      <DataGrid
        columns={columns}
        data={brands.data.data}
        fetchData = {getBrandsData}
        enablePagination={true}
        controls={{view:true}}
        onControlClick = {handleControls}
      />
    </Container>
  )
}

export default Brands