export const getNumbersBetween = (start,end,noOfDigits='')=>{
  const arr = []
  for(let i = parseInt(start); i <= parseInt(end) ; i++){
      arr.push(`${i}`.padStart(noOfDigits,'0'))
  }
  return [...arr]
}

export const getMonthsBetween = (start,end)=>{
  const arr = [
    {
      key:'01', 
      value:'JAN'
    },
    {
      key:'02', 
      value:'FEB'
    },
    {
      key:'03', 
      value:'MAR'
    },
    {
      key:'04', 
      value:'APR'
    },
    {
      key:'05', 
      value:'MAY'
    },
    {
      key:'06', 
      value:'JUN'
    },
    {
      key:'07', 
      value:'JUL'
    },
    {
      key:'08', 
      value:'AUG'
    },
    {
      key:'09', 
      value:'SEP'
    },
    {
      key:'10', 
      value:'OCT'
    },
    {
      key:'11', 
      value:'NOV'
    },
    {
      key:'12', 
      value:'DEC'
    },
  ]
  return arr.slice(parseInt(start)-1,parseInt(end))
}