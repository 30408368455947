import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {parseError} from '../../../utils/errorParser'
import { getCookie } from '../../../utils/cookiesUtil'

export const fetchUsers = createAsyncThunk('fetchUsers',async (payload)=>{
  let url = `${process.env.REACT_APP_HOSTNAME}/get-users`
  if(payload.queryParams){
    url = `${url}${payload.queryParams}`
  }
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    throw new Error("some error")
  }
})

export const fetchUserDetails = createAsyncThunk('/fetchUserDetails',async ()=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/get-user-detail`)
  return response.data
})

export const addUser = createAsyncThunk('/addUser',async (payload)=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/user/add-user`,{
    method: 'POST',
    headers: {
      'Content-type':'application/json',
      'authorization': `Bearer ${getCookie('authToken')}`
    },
    body: JSON.stringify(payload)
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const usersSlice = createSlice({
  name:'users',
  initialState:{
    users:{
      data: [],
      status: 'idle',
      error: null
    },
    userDetail:{
      data: {},
      status: 'idle',
      error: null
    },
    addUser:{
      data: {},
      status: 'idle',
      error: null
    }
  },
  reducers:{
    resetUsersState: (state,action)=>{
      state[action.payload]['status'] = 'idle'
    }
  },  
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.users.status = 'loading'
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.users.status = 'succeeded'
      state.users.data = action.payload
    },
    [fetchUsers.rejected]: (state, action) => {
      state.users.status = 'failed'
      state.users.error = action.error.message
    },
    [fetchUserDetails.pending]: (state, action) => {
      state.userDetail.status = 'loading'
    },
    [fetchUserDetails.fulfilled]: (state, action) => {
      state.userDetail.status = 'succeeded'
      state.userDetail.data = action.payload
    },
    [fetchUserDetails.rejected]: (state, action) => {
      state.userDetail.status = 'failed'
      state.userDetail.error = action.error.message
    },
    [addUser.pending]: (state, action) => {
      state.addUser.status = 'loading'
    },
    [addUser.fulfilled]: (state, action) => {
      state.addUser.status = 'succeeded'
      state.addUser.data = action.payload
    },
    [addUser.rejected]: (state, action) => {
      state.addUser.status = 'failed'
      state.addUser.error = action.error.message
    }
  }
})


export const { resetUsersState } = usersSlice.actions
export default usersSlice.reducer