import styled,{createGlobalStyle} from "styled-components";
import { BLACK } from "./constants/colors";

export const AppStyle = styled.div`
`

export const GlobalStyled = createGlobalStyle`
  @import url(‘https://fonts.googleapis.com/css?family=Montserrat|Roboto');
  @import url(‘https://fonts.googleapis.com/icon?family=Material+Icons');
  *{
    box-sizing: border-box;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
  }
  a{
    text-decoration: none;
    color: ${BLACK}
  }
`