import { TextField, InputAdornment, Button, Typography, Box, MenuItem } from "@mui/material"
import React, {useCallback, useState} from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { isPositiveNumber, isNumber, isZeroOrValid } from "../../../../utils/textValidatorUtils";
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import DatePicker from "../DatePicker";
import SearchableDropdown from '../SearchableDropdown'

export default function FormItem(props){
  const {type, label, name, value='', variant, 
    required, error, onChange, desc, accept, 
    multiple, min, max, currency, options=[],
    rows=4, multiline=false, dataFetchAction,
    dataFetchResetAction, dataFetchQueryName, 
    dataFetchStateName, optionLabelKey, defaultFetchQueryParams
  } = props

  let ele = ''
  const [fileUrls,setFileUrls] = useState([])

  const handleFileChange = useCallback((e)=>{
    const fUrls = [...e.target.files].map((file)=>{
      return {
        extension: file.type,
        url: URL.createObjectURL(file)
      }
    })
    setFileUrls([...fUrls])
    onChange(name,[...e.target.files])
  },[setFileUrls,name,onChange])

  const handleRemoveFile = useCallback((i)=>{
    const newFileUrls = fileUrls.filter((fUrl,index)=>{
      return i !== index
    })
    setFileUrls([...newFileUrls])
    onChange(name,[...newFileUrls])
  },[fileUrls,name,onChange])

  const handleChange = useCallback((val)=>{
    if(isZeroOrValid(val)){
      switch(type){
        case 'mobile':
          if(!isPositiveNumber(val)){
            return
          }
          if(val.length > 10){
            return
          }
          break;
        case 'number':
        case 'amount':
          if(!isNumber(val)){
            return
          }
          if(isZeroOrValid(min) && val<min){
            return
          }
          if(isZeroOrValid(max) && val>max){
            return
          }
          break;
        default: 
          break;
      }
    }
    onChange(name,['number','amount'].includes(type)? Number(val): val)
  }, [name, onChange,
     min, max, type
    ])

  switch(type){
    case 'password':
      ele = <TextField
        type={type}
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        onChange={(e)=>handleChange(e.target.value)}
        fullWidth
      />
    break
    case 'text':
    case 'email':
      ele = <TextField
        type={type}
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        onChange={(e)=>handleChange(e.target.value)}
        fullWidth
        multiline={multiline}
        rows={rows}
      />
      break;
    case 'mobile':
      ele = <TextField
        type="text"
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        InputProps={{
          startAdornment: <InputAdornment position="start">+91</InputAdornment>
        }}
        inputProps={{ inputMode: 'numeric' }}
        onChange={(e)=>handleChange(e.target.value)}
        fullWidth
      />
      break;
    case 'number':
      ele = <TextField
        type="text"
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        onChange={(e)=>handleChange(e.target.value)}
        inputProps={{ inputMode: 'numeric' }}
        fullWidth
      />
      break;
    case 'amount':
      ele = <TextField
        type="text"
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        InputProps={{
          startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
        }}
        inputProps={{ inputMode: 'numeric' }}
        onChange={(e)=>handleChange(e.target.value)}
        fullWidth
      />
      break;
    case 'dropdown':
      ele = <TextField
          select
          name={name}
          variant={variant}
          required={required}
          label={label}
          value={value}
          error={Boolean(error)}
          helperText={error || desc}
          onChange={(e)=>handleChange(e.target.value)}
          fullWidth
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        break;
    case 'autocomplete':
      ele = <SearchableDropdown
        options={options}
        optionLabelKey={optionLabelKey}
        value={value}
        handleChange={handleChange}
        required={required}
        variant={variant}
        error={error}
        desc={desc}
        label={label}
        dataFetchAction={dataFetchAction}
        dataFetchStateName={dataFetchStateName}
        dataFetchQueryName={dataFetchQueryName}
        defaultFetchQueryParams={defaultFetchQueryParams}
        dataFetchResetAction={dataFetchResetAction}
      />
      break
    case 'datepicker':
        ele = <DatePicker
        name={name}
        variant={variant}
        required={required}
        label={label}
        value={value}
        error={Boolean(error)}
        helperText={error || desc}
        onChange={handleChange}
        min={min}
        max={max}
        fullWidth
      />
      break;
    case 'file':
      ele = (
        <Box 
          borderColor={error?'red':'grey.400'}
          borderRadius={5} border={1} align="center" p={2}>
            {
              fileUrls.length > 0?
              <div>
                {
                  fileUrls.map((furl,i)=>{
                    return (
                      <Box position="relative"
                        display="inline-block" 
                        p={2}
                        key={i}
                      >
                        <Box
                          position="absolute"
                          top="0"
                          right="0"
                        >
                          <CancelSharpIcon 
                            color="secondary"
                            onClick={()=>{handleRemoveFile(i)}}
                          />
                        </Box>
                        <object 
                          type={furl.extension} 
                          data={furl.url} 
                          width="200" 
                          height="200"
                        >  
                        </object>
                      </Box>
                    )
                  })
                }
              </div>
              :
              <>
                <input
                  accept={accept}
                  id="contained-button-file"
                  multiple={multiple}
                  type="file"
                  hidden
                  onChange={handleFileChange}
                />
                <Typography color="textSecondary" gutterBottom>
                  {label}
                </Typography>
                <label htmlFor="contained-button-file">
                  <Button
                    variant={variant}
                    color="secondary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                  </Button>
                </label> 
              </>
            }
        </Box>
      )
      break;
    default: 
      ele = null
  }

  return ele
}