import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {parseError} from '../../../utils/errorParser'
import { getCookie } from '../../../utils/cookiesUtil'

export const fetchOtps = createAsyncThunk('fetchOtps',async (payload)=>{
  let url = `${process.env.REACT_APP_HOSTNAME}/get-otps`
  if(payload.queryParams){
    url = `${url}${payload.queryParams}`
  }
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const otpsSlice = createSlice({
  name:'otps',
  initialState:{
    otps:{
      data: [],
      status: 'idle',
      error: null
    }
  },
  reducers:{
    resetOtpsState: (state,action)=>{
      state[action.payload]['status'] = 'idle'
    }
  },  
  extraReducers: {
    [fetchOtps.pending]: (state, action) => {
      state.otps.status = 'loading'
    },
    [fetchOtps.fulfilled]: (state, action) => {
      state.otps.status = 'succeeded'
      state.otps.data = action.payload
    },
    [fetchOtps.rejected]: (state, action) => {
      state.otps.status = 'failed'
      state.otps.error = action.error.message
    }
  }
})


export const { resetOtpsState } = otpsSlice.actions
export default otpsSlice.reducer