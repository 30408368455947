import styled from "styled-components";
import { BACKGROUND_GRAY } from "../../constants/colors";

export const LoginStyle = styled.div`
  .h-100{
    height: 100vh;
  }
  .bg-gray{
    background-color: ${BACKGROUND_GRAY};
  }
`