import {AddProductStyle} from './index.style'
import Container from '../../../components/Container'
import { getProductForm } from './constants'
import Form from '../../../components/Form'
import { Box, Typography, Paper, Button } from '@mui/material'
import { useCallback, useRef } from 'react'
import { uploadFile } from '../../../utils/imageUpload'
import { useDispatch, useSelector } from 'react-redux'
import { addProduct, resetProductsState } from '../../../app/actionReducers/products'
import { showLoader, showSnackbar } from '../../../app/actionReducers/utilsHelper'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

export default function AddProduct(props){
  const history = useHistory()

  const formData = getProductForm()
  const submitFormRef = useRef('')
  const dispatch = useDispatch()

  const addProductData = useSelector(state => state.products.addProduct)

  const handleSubmit = useCallback(async (e)=>{
    const response = submitFormRef.current(e)
    if(response){
      const fileUploadResp = await uploadFile(response['productImage'])
      if(fileUploadResp.success){
        dispatch(showLoader(true))
        dispatch(addProduct({
          ...response,
          brandId: response.brandId.brandId,
          supplierId: response.supplierId.userId,
          categoryId: response.categoryId.categoryId,
          productImage: fileUploadResp.data.data
        }))
      }else{
        dispatch(showSnackbar({
          message: fileUploadResp.error,
          severity: 'error'
        }))
      }    }
  },[dispatch])

  useEffect(() => {
    if (addProductData.status === 'failed' || addProductData.status === 'succeeded') {
      if(addProductData.status === 'succeeded'){
        history.push('/products')
      }else{
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: addProductData.error,
          severity: 'error'
        }))
      }
      dispatch(resetProductsState('addProduct'))
    }
  }, [addProductData, dispatch, history])

  const getSubmitMethod = useCallback((submitMethod)=>{
    submitFormRef.current = submitMethod
  },[])

  return(
    <Container>
      <AddProductStyle>
        <Typography gutterBottom 
          variant="h5" 
          color="textSecondary"
          align="center"
        >
          Add New Product
        </Typography>
        <Box width="100%"
          component={Paper}
          boxShadow={2}
          variant="outlined"
          p={3}
          height="100%"
          mt={3}
          pb={0}
        >
          <Form
            formData = {formData}
            getSubmitMethod = {getSubmitMethod}
          />
          <Box mb={3} mt={2} textAlign="right">
            <Button variant="contained" size="large" gutterTop color="primary" onClick={handleSubmit}>
              Add Product
            </Button>
          </Box>
        </Box>
      </AddProductStyle>
    </Container>
  )
}