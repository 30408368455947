export const SUPPLIER = 'supplier'
export const CUSTOMER = 'customer'
export const ADMIN = 'admin'

export const AVAILABLE_ROLES = [
  SUPPLIER,CUSTOMER,ADMIN  
]

export const getUserForm = ({handleRoleChange,showCompanyName}) => {
  let userForm = [
    {
      label: 'Enter Name',
      name: 'name',
      type: 'text',
      placeholder: 'Name',
      desc:'',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Role',
      name: 'role',
      type: 'dropdown',
      placeholder: 'Select Role',
      desc:'',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
      options:[
        {
          name:'Customer',
          value: CUSTOMER
        },
        {
          name:'Supplier',
          value: SUPPLIER
        },
      ],
      onChange: handleRoleChange,
      width:12
    },
    {
      label: 'Enter Company Name',
      name: 'companyName',
      type: 'text',
      placeholder: 'Company Name',
      desc:'',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
      width:12,
      visible: showCompanyName
    },
    {
      label: 'Enter Mobile',
      name: 'phone',
      type: 'mobile',
      placeholder: 'Mobile No',
      desc:'',
      defaultValue: '',
      min: 5,
      max: 10,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Email',
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      desc:'',
      defaultValue: '',
      min: '',
      max: '',
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Password',
      name: 'password',
      type: 'password',
      placeholder: 'password',
      desc:'',
      defaultValue: '',
      min: '',
      max: '',
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    }
  ]
  return userForm
}