import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {parseError} from '../../../utils/errorParser'
import { getCookie } from '../../../utils/cookiesUtil'

export const fetchCategories = createAsyncThunk('categories/fetchCategories',async (payload)=>{
  let url = `${process.env.REACT_APP_HOSTNAME}/category/get-categories`
  if(payload.queryParams){
    url = `${url}${payload.queryParams}`
  }
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    throw new Error("some error")
  }
})

export const fetchCategoryDetails = createAsyncThunk('/categories/fetchCategoryDetails',async ()=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/get-category-detail`)
  return response.data
})

export const addCategory = createAsyncThunk('/categories/addCategory',async (payload)=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/category/add-category`,{
    method: 'POST',
    headers: {
      'Content-type':'application/json',
      'authorization': `Bearer ${getCookie('authToken')}`
    },
    body: JSON.stringify(payload)
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const categoriesSlice = createSlice({
  name:'categories',
  initialState:{
    categories:{
      data: [],
      status: 'idle',
      error: null
    },
    categoryDetail:{
      data: {},
      status: 'idle',
      error: null
    },
    addCategory:{
      data: {},
      status: 'idle',
      error: null
    }
  },
  reducers:{
    resetCategoriesState: (state,action)=>{
      state[action.payload]['status'] = 'idle'
    }
  },  
  extraReducers: {
    [fetchCategories.pending]: (state, action) => {
      state.categories.status = 'loading'
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.categories.status = 'succeeded'
      state.categories.data = action.payload
    },
    [fetchCategories.rejected]: (state, action) => {
      state.categories.status = 'failed'
      state.categories.error = action.error.message
    },
    [fetchCategoryDetails.pending]: (state, action) => {
      state.categoryDetail.status = 'loading'
    },
    [fetchCategoryDetails.fulfilled]: (state, action) => {
      state.categoryDetail.status = 'succeeded'
      state.categoryDetail.data = action.payload
    },
    [fetchCategoryDetails.rejected]: (state, action) => {
      state.categoryDetail.status = 'failed'
      state.categoryDetail.error = action.error.message
    },
    [addCategory.pending]: (state, action) => {
      state.addCategory.status = 'loading'
    },
    [addCategory.fulfilled]: (state, action) => {
      state.addCategory.status = 'succeeded'
      state.addCategory.data = action.payload
    },
    [addCategory.rejected]: (state, action) => {
      state.addCategory.status = 'failed'
      state.addCategory.error = action.error.message
    }
  }
})


export const { resetCategoriesState } = categoriesSlice.actions
export default categoriesSlice.reducer