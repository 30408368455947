import { createSlice } from '@reduxjs/toolkit';

export const utilsHelper = createSlice({
  name: 'utilsHelper',
  initialState: {
    loader: {
      show: false
    },
    snackbar: {
      show: false,
      severity: "error", //can be error/success/warning/info
      message: ''
    }
  },
  reducers: {
    showLoader: (state,action) => {
      state.loader.show = action.payload
    },
    showSnackbar:(state,{payload})=>{
      state.snackbar = {
        show: (payload.show === undefined?true:payload.show),
        message: payload.message,
        severity: payload.severity?payload.severity:state.snackbar.severity
      }
    }
  }
});

export const { showLoader,showSnackbar } = utilsHelper.actions;

export default utilsHelper.reducer;
