import { CircularProgress, Snackbar, Alert } from '@mui/material';
import {useSelector,useDispatch} from 'react-redux'
import {UtilsHelperStyle} from './index.style'
import { useCallback } from "react";
import { showSnackbar } from "../../app/actionReducers/utilsHelper";

export default function UtilsHelper(){
  const loader = useSelector(state => state.utils.loader)
  const snackbar = useSelector(state => state.utils.snackbar)
  const dispatch = useDispatch()

  const handleClose = useCallback(()=>{
    dispatch(showSnackbar({
      show:false
    }))
  },[dispatch])

  return(
    <UtilsHelperStyle>
      {
        loader.show && 
        <div className="loader-wrapper">
          <CircularProgress
            color="secondary"
            className="loader"
          />
        </div>
      }
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        open={snackbar.show}
        onClose={handleClose}
        autoHideDuration={5000}
      >
        <Alert elevation={6} variant="filled" severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </UtilsHelperStyle>
  )
}