export const getSupplierForm = (data) => {
  let supplierForm = [
    {
      label: 'Enter hostname',
      name: 'name',
      type: 'text',
      placeholder: 'Name',
      desc:'',
      defaultValue: data?.hostname,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Company Name',
      name: 'companyName',
      type: 'text',
      placeholder: 'Company Name',
      desc:'',
      defaultValue: '',
      defaultValue: data?.companyName,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Mobile',
      name: 'phone',
      type: 'mobile',
      placeholder: 'Mobile No',
      desc:'',
      defaultValue: data?.phone,
      min: 5,
      max: 10,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Email',
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      desc:'',
      defaultValue: data?.email,
      min: '',
      max: '',
      regex: '',
      variant: 'outlined',
      width:12
    }
  ]
  return supplierForm
}
