import { AddUserStyle } from './index.style'
import Container from '../../../components/Container'
import { getUserForm, SUPPLIER } from './constants'
import Form from '../../../components/Form'
import { Box, Grid, Typography, Paper, Button } from '@mui/material'
import { useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUser, resetUsersState } from '../../../app/actionReducers/users'
import { showSnackbar, showLoader } from '../../../app/actionReducers/utilsHelper'
import { useState } from 'react'
import { useHistory } from "react-router-dom";
import Address from '../../../components/Address'

export default function AddUser(props) {
  const [showCompanyName, setShowCompanyName] = useState(false)
  const [showAddress, setShowAddress] = useState(false)
  const history = useHistory()

  const handleRoleChange = useCallback((role)=>{
    if(role === SUPPLIER){
      setShowAddress(true)
      setShowCompanyName(true)
    }else{
      setShowCompanyName(false)
    }
  },[])

  const formData = getUserForm({handleRoleChange,showCompanyName})
  const submitFormRef = useRef('')
  const submitAddressRef = useRef('')
  
  const dispatch = useDispatch()

  const addUserData = useSelector(state => state.users.addUser)

  const handleSubmit = useCallback(async (e) => {
    const responseForm = submitFormRef.current(e)

    if (responseForm) {
      let responseAddressForm = {}
      if(showAddress){
        responseAddressForm = submitAddressRef.current(e)
        if(!responseAddressForm){
          return
        }
      }
      dispatch(showLoader(true))
      dispatch(addUser({
        ...responseForm,
        address: responseAddressForm
      }))
    }
  }, [dispatch, showAddress])

  useEffect(() => {
    if (addUserData.status === 'failed' || addUserData.status === 'succeeded') {
      if(addUserData.status === 'succeeded'){
        history.push('/users')
      }else{
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: addUserData.error,
          severity: 'error'
        }))
      }
      dispatch(resetUsersState('addUser'))
    }
  }, [addUserData, dispatch, history])

  const getSubmitMethod = useCallback((submitMethod, methodType) => {
    if(methodType === 'ADDRESS'){
      submitAddressRef.current = submitMethod
    }else{
      submitFormRef.current = submitMethod
    }
  }, [])

  return (
    <Container>
      <AddUserStyle>
        <Grid container justify="center">
          <Grid item xs={12} lg={3} >
            <Box width="100%"
              component={Paper}
              boxShadow={2}
              variant="outlined"
              p={3}
              height="100%"
              mt={3}
              pb={0}
            >
              <Typography gutterBottom
                variant="h5"
                color="textSecondary"
                align="center"
              >
                Add New User
            </Typography>
              <Form
                formData={formData}
                getSubmitMethod={getSubmitMethod}
              />
              {
                showAddress &&
                <Box mt={2} >
                  <Typography 
                    gutterBottom
                    variant="h6"
                    color="textSecondary"
                    align="center"
                  >
                    Company's Address Detail
                  </Typography>
                  <Address getSubmitMethod={getSubmitMethod}/>
                </Box>
                
              }
              <Box mb={0} mt={2} textAlign="right">
                <Button variant="contained" size="large" gutterTop color="secondary" onClick={handleSubmit}>
                  Create User
              </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AddUserStyle>
    </Container>
  )
}