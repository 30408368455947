export const getLoginForm = ()=>{
  return [
    {
      label: 'Enter Mobile',
      name: 'username',
      type: 'mobile',
      placeholder: 'Mobile',
      desc:'',
      defaultValue: '',
      regex: '',
      required: true,
      variant: 'outlined',
      width: 12
    }, {
      label: 'Enter password',
      name: 'password',
      type: 'password',
      placeholder: 'password',
      desc:'',
      defaultValue: '',
      min: 3,
      max: 30,
      regex: '',
      required: true,
      variant: 'outlined',
      width: 12
    }
  ]
}