export const SUPPLIER = 'supplier'
export const CUSTOMER = 'customer'
export const ADMIN = 'admin'

export const AVAILABLE_ROLES = [
  SUPPLIER,CUSTOMER,ADMIN  
]

export const getAddressForm = (data) => {
  let addressForm = [
    {
      label: 'Enter Name',
      name: 'name',
      type: 'text',
      placeholder: 'Name',
      desc:'',
      defaultValue: data?.name,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Mobile',
      name: 'phone',
      type: 'mobile',
      placeholder: 'Mobile No',
      desc:'',
      defaultValue: data?.phone,
      min: 5,
      max: 10,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Pincode',
      name: 'pincode',
      type: 'number',
      placeholder: 'Pincode',
      desc:'',
      defaultValue: data?.pincode,
      min: 0,
      max: 999999,
      regex: new RegExp('^[0-9]{6,6}$'),
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Locality',
      name: 'locality',
      type: 'text',
      placeholder: 'locality',
      desc:'',
      defaultValue: data?.locality,
      regex: '',
      required: true,
      variant: 'outlined',
      width: 12
    },
    {
      label: 'Enter Address',
      name: 'address',
      type: 'text',
      placeholder: 'Address',
      desc:'',
      defaultValue: data?.address,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter City',
      name: 'city',
      type: 'text',
      placeholder: 'City',
      desc:'',
      defaultValue: data?.city,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter State',
      name: 'state',
      type: 'text',
      placeholder: 'State',
      desc:'',
      defaultValue: data?.state,
      regex: '',
      required: true,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Latitude',
      name: 'lat',
      type: 'text',
      placeholder: 'Latitude',
      desc:'',
      defaultValue: data?.lat,
      regex: '',
      required: false,
      variant: 'outlined',
      width:12
    },
    {
      label: 'Enter Longitude',
      name: 'lng',
      type: 'text',
      placeholder: 'Longitude',
      desc:'',
      defaultValue: data?.lng,
      regex: '',
      required: false,
      variant: 'outlined',
      width:12
    }
  ]
  return addressForm
}