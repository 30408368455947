import React from 'react';
import Routes from './Routes'
import {AppStyle, GlobalStyled} from './App.style.js'
import UtilsHelper from './components/UtilsHelper';

function App() {
  return (
    <AppStyle>
      <GlobalStyled/>
      <Routes/>
      <UtilsHelper/>
    </AppStyle>
  )
}

export default App
