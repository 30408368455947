import styled from "styled-components";
import { WHITE } from "../../constants/colors";

export const ContainerStyle = styled.div`
  display:flex;
  height: 100vh;
  width: 100vw;
  background-color: ${props=>props.bgColor || WHITE};
  .loader-wrapper{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${WHITE};
    z-index: 1500;
    opacity: 0.7;
  }
  .loader{
    position: absolute;
    top: 50vh;
    left: 50vw;
  }
  .flex-grow{
    flex-grow: 1;
  }
  .content{
    position: absolute;
    left: 0;
    flex-grow: 1;
    padding: 10px;
    width: 100%;
  }
  .hide{
    display:none;
  }
  .menu-button{
    margin-right: 36px;
  }
  .drawer{
    width: ${props=>props.drawerWidth};
    flex-shrink: 0;
    white-space: nowrap;
  }
  .drawer-open{
    width: ${props=>props.drawerWidth};
  }
  .drawer-close{
    width: 0px;
  }
  .sidebar-close-icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding:0px;
  }
`