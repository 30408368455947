import {queryBuilder} from '../utils/common'
import Cookie from 'js-cookie'

const authGet = async ({url,query={}})=>{
  const queryString = queryBuilder(query)
  try{
    const response = await fetch(`${url}/${queryString}`,{
      headers: {
        'authorization': `Bearer ${Cookie.get('authToken')}`
      }
    })
    if(response.ok){
      const json = await response.json()
      return json
    }else{
      const responseErr = await response.json()
      throw responseErr
    }
  }catch(err){
    throw err
  }
}

export default authGet