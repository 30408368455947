export const queryBuilder = (finalFilter) => {
  const queryArray = []
  Object.keys(finalFilter).forEach((key) => {
    const keyValue = finalFilter[key]
    let query = ''
    const identifier = queryArray.length ? '&' : '?'
    if (Array.isArray(keyValue)) {
      keyValue.forEach((value) => {
        query += `${query ? '&' : ''}${key}=${value}`
      })
    } else if (keyValue) {
      query += `${key}=${keyValue}`
    }
    if (query) {
      queryArray.push(`${identifier}${query}`)
    }
  })
  return queryArray.join('')
}
