import { useState, useCallback } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { showSnackbar } from '../../../../app/actionReducers/utilsHelper';
import { queryBuilder } from '../../../../utils/common';

function SearchableDropdown(props){
  const dispatch = useDispatch()

  const [options, setOptions] = useState(props.options)
  
  const responseData = useSelector(state => state[props.dataFetchStateName][props.dataFetchStateName])

  const handleInputChange = useCallback(async (val)=>{
    if(val && props.dataFetchAction && props.dataFetchQueryName && props.dataFetchStateName){
      const query={}
      if(props.defaultFetchQueryParams){
        props.defaultFetchQueryParams.forEach((qp)=>{
          query[qp.key] = qp.value
        })
      }
      query[props.dataFetchQueryName] = val

      dispatch(props.dataFetchAction({
        queryParams: queryBuilder(query)
      }))
    }
  },[dispatch,props])

  useEffect(()=>{
    if (responseData.status === 'failed' || responseData.status === 'succeeded') {
      if(responseData.status === 'failed'){
        dispatch(showSnackbar({
          message: responseData.error,
          severity: 'error'
        }))
      }else{
        setOptions(responseData.data.data)
      }
      dispatch(props.dataFetchResetAction(props.dataFetchStateName))
    }
  }, [responseData, dispatch, props])

  const {value, handleChange, required, variant, error, desc, label} = props

  return(
    <Autocomplete
      options={options}
      getOptionLabel={(option) => {
        return option[props.optionLabelKey]? option[props.optionLabelKey]: ""       
      }}
      value={value}
      onInputChange={(e, newVal)=>{
        handleInputChange(newVal)
      }}
      onChange={(e, newInputValue) => {
        handleChange(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          variant={variant}
          error={Boolean(error)}
          helperText={error || desc}
          inputProps={{
            ...params.inputProps
          }}
          fullWidth
        />
      )}
    />
  )
}

export default SearchableDropdown