import {useEffect, useMemo, useCallback} from 'react'
import { fetchSuppliers, resetSuppliersState } from '../../app/actionReducers/suppliers'
import {useDispatch, useSelector} from 'react-redux'
import Container from '../../components/Container'
import DataGrid from '../../components/DataGrid'
import { useHistory } from "react-router-dom";
import { showSnackbar,showLoader } from '../../app/actionReducers/utilsHelper'

function Suppliers(){
  const suppliers = useSelector(state => state.suppliers.suppliers)
  const history = useHistory()
  const dispatch = useDispatch()

  const columns = useMemo(()=>[
    {
      key: 'supplierId',
      text: 'Supplier Id',
      enableSearch: true
    },
    {
      key: 'userId',
      text: 'User Id',
      enableSearch: true
    },
    {
      key: 'companyName',
      text: 'Company Name',
      enableSearch: true
    },
    {
      key: 'hostname',
      text: 'Hostname',
      enableSearch: true
    },
    {
      key: 'phone',
      text: 'Phone No',
      enableSearch: true
    },
    {
      key: 'status',
      text: 'Status'
    }
  ],[])

  useEffect(()=>{
    if (suppliers.status === 'failed' || suppliers.status === 'succeeded') {
      if(suppliers.status === 'failed'){
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: suppliers.error,
          severity: 'error'
        }))
      }
      dispatch(resetSuppliersState('suppliers'))
    }
  }, [suppliers, dispatch])
  
  const handleControls = useCallback((control, data) => {
    switch (control) {
      case 'view':
        history.push(`/supplier/edit/${data.supplierId}`)
        break
      default:
    }
  }, [history])

  // const handleAddUser = useCallback(()=>{
  //   history.push('/add-user')
  // },[history])

  //this function is basically a data call for the GRID
  const getSuppliersData = useCallback((queryParams)=>{
    dispatch(fetchSuppliers({
      queryParams: queryParams
    }))
  },[dispatch])

  dispatch(showLoader(suppliers.status==='loading'))

  return(
    <Container>
      <DataGrid
        columns={columns}
        data={suppliers.data.data}
        fetchData = {getSuppliersData}
        enablePagination={true}
        controls={{ view: true }}
        onControlClick={handleControls}
      />
    </Container>
  )
}

export default Suppliers