import { DataGridStyle } from "./index.style";
import {Component} from 'react'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { IconButton, Box } from '@mui/material';
import { BACKGROUND_GRAY } from "../../constants/colors";
import { queryBuilder } from "../../utils/common";

/*
  props:
  1.*columns = [{text:'',key:'',enableSearch:true/false,render:()=>{}}]
  2.controls = {view=true/false,update:true/false,delete:true/false,changeStatus:true/false} 
  3.onControlClick = (control,row)=>{}
  4.enablePagination = true/false,
  5.paginationControls = {pageSize}
  6. *fetchData = (query)=>{} //this will be called with query params whenever the grid needs to fetch data
  7. *data = [{},{}]
*/

class DataGrid extends Component {

  state = {
    pageNumber: 0
  }

  fetchData = async () => {
    let enablePagination = this.props.enablePagination;

    let pageSize = this.props.paginationControls.pageSize;
    let pageNumber = this.state.pageNumber;

    let qParams = { enablePagination, pageSize, pageNumber };

    this.props.columns.forEach(element => {
      if(this.state[element.key] || this.state[element.key] === '0'){
        qParams[element.key] = this.state[element.key];
      }
    });

    let query = queryBuilder(qParams)

    this.props.fetchData(query)
  }

  changePage = (type) => {
    if (type === 'next') {
      this.setState({
        pageNumber: this.state.pageNumber + 1
      }, () => {
        this.fetchData();
      });
    } else if (this.state.pageNumber > 0) {
      this.setState({
        pageNumber: this.state.pageNumber - 1
      }, () => {
        this.fetchData();
      });
    }
  }

  componentDidMount() {
    let searchFields = {};

    this.props.columns.forEach(element => {
      searchFields[element.key] = '';
    });

    this.setState(searchFields, () => {
      this.fetchData();
    });
  }

  search = (e) => {
    if (e.keyCode === 13) {
      this.fetchData();
    }
  }

  handleSeachChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    let { columns, controls, onControlClick, enablePagination, data=[] } = this.props;
    let { pageNumber } = this.state;
    return (
      <DataGridStyle>
        {
          enablePagination &&
          <TablePagination
            count={-1}
            rowsPerPageOptions={[]}
            component="div"
            rowsPerPage={this.props.paginationControls.pageSize}
            page={pageNumber}
            onPageChange={(e,newPage)=>{
              if(newPage < pageNumber){
                this.changePage('previous')
              }else{
                this.changePage('next')
              }
            }}
          />
        }
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow bgColor={BACKGROUND_GRAY}>
              {
                columns.map((head, headInd) => {
                  return (
                    <TableCell key={headInd}>
                      {head.text}
                    </TableCell>
                  )
                })
              }
              {
                (controls.update || controls.delete || controls.view || controls.changeStatus) && <TableCell></TableCell> 
              }
            </TableRow>
            <TableRow>
              {
                columns.map((head, headInd) => {
                  if(head.enableSearch){
                    return (
                      <TableCell key={headInd}>
                        <TextField
                          label={head.text}
                          type="text"
                          size="small"
                          name={`${head.key}`}
                          value={this.state[head.key]}
                          onKeyDown={this.search}
                          onChange={this.handleSeachChange}
                        />
                      </TableCell>
                    )
                  }else{
                    return (
                      <TableCell key={headInd}></TableCell>
                    )
                  }
                })
              }
              {
                (controls.update || controls.delete || controls.view || controls.changeStatus) && <TableCell></TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map((row, rowNum) => {
                return (
                  <TableRow key={rowNum}>
                    {
                      columns.map((cell, cellInd) => {
                        return (
                          <TableCell key={`${rowNum}${cellInd}`}>
                            {
                              (cell.render)?cell.render(row[cell.key]):row[cell.key]
                            }
                          </TableCell>
                        )
                      })
                    }
                    {
                      (controls.update || controls.delete || controls.changeStatus || controls.view) ?
                        <TableCell>
                          <Box>
                            {
                              (controls.view) &&
                              <IconButton onClick={()=>onControlClick('view',row)}>
                                <VisibilityIcon color="secondary"/>
                              </IconButton>
                            }
                            {
                              (controls.update) &&
                              <IconButton onClick={()=>onControlClick('update',row)}>
                                <EditIcon color="secondary"/>
                              </IconButton>
                            }

                            {
                              (controls.delete) &&
                              <IconButton onClick={()=>onControlClick('delete',row)}>
                                <DeleteIcon color="secondary"/>
                              </IconButton>
                            }

                            {
                              (controls.changeStatus) ?
                                <IconButton onClick={()=>onControlClick('changeStatus',row)}>
                                  {
                                    (row.status === 'a') ?
                                    <NotInterestedIcon color="secondary"/>
                                    :
                                    <CheckCircleIcon color="secondary"/>
                                  }
                                </IconButton>
                                :
                                null
                            }
                          </Box>
                        </TableCell>
                        :
                        null
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        </TableContainer>
      </DataGridStyle>
    )
  }
}

DataGrid.defaultProps = {
  controls: { update: false, delete: false, changeStatus: false, view: false },
  enablePagination: true,
  paginationControls: { pageSize: 50 }
};

export default DataGrid;