import { AddBrandStyle } from './index.style'
import Container from '../../../components/Container'
import { getBrandForm } from './constants'
import Form from '../../../components/Form'
import { Box, Grid, Typography, Paper, Button } from '@mui/material'
import { useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addBrand, resetBrandsState } from '../../../app/actionReducers/brands'
import { showSnackbar, showLoader } from '../../../app/actionReducers/utilsHelper'
import { useHistory } from "react-router-dom";
import { uploadFile } from '../../../utils/imageUpload'

export default function AddBrand(props) {
  const history = useHistory()

  const formData = getBrandForm()
  const submitFormRef = useRef('')
  const dispatch = useDispatch()

  const addBrandData = useSelector(state => state.brands.addBrand)

  const handleSubmit = useCallback(async (e) => {
    const response = submitFormRef.current(e)
    if (response) {
      const fileUploadResp = await uploadFile(response['brandImage'])
      if(fileUploadResp.success){
        dispatch(showLoader(true))
        dispatch(addBrand({
          ...response,
          brandImage: fileUploadResp.data.data
        }))
      }else{
        dispatch(showSnackbar({
          message: fileUploadResp.error,
          severity: 'error'
        }))
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (addBrandData.status === 'failed' || addBrandData.status === 'succeeded') {
      if(addBrandData.status === 'succeeded'){
        history.push('/brands')
      }else{
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: addBrandData.error,
          severity: 'error'
        }))
      }
      dispatch(resetBrandsState('addBrand'))
    }
  }, [addBrandData, dispatch, history])

  const getSubmitMethod = useCallback((submitMethod) => {
    submitFormRef.current = submitMethod
  }, [])

  return (
    <Container>
      <AddBrandStyle>
        <Grid container justify="center">
          <Grid item xs={12} lg={3} >
            <Box width="100%"
              component={Paper}
              boxShadow={2}
              variant="outlined"
              p={3}
              height="100%"
              mt={3}
              pb={0}
            >
              <Typography gutterBottom
                variant="h5"
                color="textSecondary"
                align="center"
              >
                Add New Brand
            </Typography>
              <Form
                formData={formData}
                getSubmitMethod={getSubmitMethod}
              />
              <Box mb={0} mt={2} textAlign="right">
                <Button variant="contained" size="large" gutterTop color="secondary" onClick={handleSubmit}>
                  Add Brand
              </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AddBrandStyle>
    </Container>
  )
}