import { AddCategoryStyle } from './index.style'
import Container from '../../../components/Container'
import { getCategoryForm } from './constants'
import Form from '../../../components/Form'
import { Box, Grid, Typography, Paper, Button } from '@mui/material'
import { useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCategory, resetCategoriesState } from '../../../app/actionReducers/categories'
import { showSnackbar, showLoader } from '../../../app/actionReducers/utilsHelper'
import { useHistory } from "react-router-dom";
import { uploadFile } from '../../../utils/imageUpload'

export default function AddCategory(props) {
  const history = useHistory()

  const formData = getCategoryForm()
  const submitFormRef = useRef('')
  const dispatch = useDispatch()

  const addCategoryData = useSelector(state => state.categories.addCategory)

  const handleSubmit = useCallback(async (e) => {
    const response = submitFormRef.current(e)
    if (response) {
      const fileUploadResp = await uploadFile(response['categoryImage'])
      if(fileUploadResp.success){
        dispatch(showLoader(true))
        dispatch(addCategory({
          ...response,
          categoryImage: fileUploadResp.data.data
        }))
      }else{
        dispatch(showSnackbar({
          message: fileUploadResp.error,
          severity: 'error'
        }))
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (addCategoryData.status === 'failed' || addCategoryData.status === 'succeeded') {
      if(addCategoryData.status === 'succeeded'){
        history.push('/categories')
      }else{
        dispatch(showLoader(false))
        dispatch(showSnackbar({
          message: addCategoryData.error,
          severity: 'error'
        }))
      }
      dispatch(resetCategoriesState('addCategory'))
    }
  }, [addCategoryData, dispatch, history])

  const getSubmitMethod = useCallback((submitMethod) => {
    submitFormRef.current = submitMethod
  }, [])

  return (
    <Container>
      <AddCategoryStyle>
        <Grid container justify="center">
          <Grid item xs={12} lg={3} >
            <Box width="100%"
              component={Paper}
              boxShadow={2}
              variant="outlined"
              p={3}
              height="100%"
              mt={3}
              pb={0}
            >
              <Typography gutterBottom
                variant="h5"
                color="textSecondary"
                align="center"
              >
                Add New Category
              </Typography>
              <Form
                formData={formData}
                getSubmitMethod={getSubmitMethod}
              />
              <Box mb={0} mt={2} textAlign="right">
                <Button variant="contained" size="large" gutterTop color="secondary" onClick={handleSubmit}>
                  Add Category
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AddCategoryStyle>
    </Container>
  )
}