import React, {useCallback} from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SMSIcon from '@mui/icons-material/SmsRounded';
import LabelIcon from '@mui/icons-material/Label';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ContainerStyle } from './index.style';
import CategoryIcon from '@mui/icons-material/Category';
import ShopIcon from '@mui/icons-material/Shop';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout, resetAuthState } from '../../app/actionReducers/auth';
import { showSnackbar, showLoader } from '../../app/actionReducers/utilsHelper';
import { useEffect } from 'react';

const drawerWidth = '250px';
const navbarContent = [
  // {
  //   name: 'Products',
  //   to: '/products',
  //   icon: <ShopIcon/>
  // },
  {
    name: 'Product Requests',
    to: '/product-requests',
    icon: <ShopIcon/>
  },
  {
    name: 'Users',
    to: '/users',
    icon: <AccountBoxIcon/>
  },
  {
    name: 'Suppliers',
    to: '/suppliers',
    icon: <AccountBoxIcon/>
  },
  {
    name: 'Otps',
    to: '/otps',
    icon: <SMSIcon/>
  },
  // {
  //   name: 'Brands',
  //   to: '/brands',
  //   icon: <LabelIcon/>
  // },
  // {
  //   name: 'Categories',
  //   to: '/categories',
  //   icon: <CategoryIcon/>
  // }
]

export default function Container(props) {
  const dispatch = useDispatch()
  const theme = useTheme();
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const logoutData = useSelector(state => state.auth.logout)

  const handleDrawerOpen = () => {
    setOpenSidebar(true);
  }

  const handleDrawerClose = () => {
    setOpenSidebar(false);
  }

  const handleLogout = useCallback(()=>{
    dispatch(logout())
  },[dispatch])

  useEffect(() => {
    if (logoutData.status === 'failed') {
      dispatch(showSnackbar({
        message: logoutData.error,
        severity: 'error'
      }))
    }
    if (logoutData.status === 'failed' || logoutData.status === 'succeeded') {
      dispatch(showLoader(false))
      dispatch(resetAuthState('logout'))
    }
  }, [logoutData, dispatch])

  return (
    <ContainerStyle bgColor={props.bgColor} drawerWidth={drawerWidth} theme={theme}>
      <AppBar
        position="fixed"
        className='app-bar'
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx('menu-button', {
              'hide': openSidebar,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Grid container width={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" noWrap className="flex-grow">
                Cpanel
              </Typography>
            </Grid>
            <Grid item>
              <Button 
                variant="contained"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx('drawer', {
          'drawer-open': openSidebar,
          'drawer-close': !openSidebar,
        })}
        classes={{
          paper: clsx({
            'drawer-open': openSidebar,
            'drawer-close': !openSidebar,
          }),
        }}
      >
        <Toolbar className='sidebar-close-icon'>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Toolbar>
        <List>
          {navbarContent.map((item) => (
            <Link to={item.to} key={item.name}>
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <main className='content'>
        <Toolbar/>
        {props.children}
      </main>
    </ContainerStyle>
  );
}
