import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {parseError} from '../../../utils/errorParser'
import { getCookie } from '../../../utils/cookiesUtil'

export const fetchBrands = createAsyncThunk('brands/fetchBrands',async (payload)=>{
  let url = `${process.env.REACT_APP_HOSTNAME}/brand/get-brands`
  if(payload.queryParams){
    url = `${url}${payload.queryParams}`
  }
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    throw new Error("some error")
  }
})

export const fetchBrandDetails = createAsyncThunk('/brands/fetchBrandDetails',async ()=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/get-brand-detail`)
  return response.data
})

export const addBrand = createAsyncThunk('/brands/addBrand',async (payload)=>{
  const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/brand/add-brand`,{
    method: 'POST',
    headers: {
      'Content-type':'application/json',
      'authorization': `Bearer ${getCookie('authToken')}`
    },
    body: JSON.stringify(payload)
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const brandsSlice = createSlice({
  name:'brands',
  initialState:{
    brands:{
      data: [],
      status: 'idle',
      error: null
    },
    brandDetail:{
      data: {},
      status: 'idle',
      error: null
    },
    addBrand:{
      data: {},
      status: 'idle',
      error: null
    }
  },
  reducers:{
    resetBrandsState: (state,action)=>{
      state[action.payload]['status'] = 'idle'
    }
  },  
  extraReducers: {
    [fetchBrands.pending]: (state, action) => {
      state.brands.status = 'loading'
    },
    [fetchBrands.fulfilled]: (state, action) => {
      state.brands.status = 'succeeded'
      state.brands.data = action.payload
    },
    [fetchBrands.rejected]: (state, action) => {
      state.brands.status = 'failed'
      state.brands.error = action.error.message
    },
    [fetchBrandDetails.pending]: (state, action) => {
      state.brandDetail.status = 'loading'
    },
    [fetchBrandDetails.fulfilled]: (state, action) => {
      state.brandDetail.status = 'succeeded'
      state.brandDetail.data = action.payload
    },
    [fetchBrandDetails.rejected]: (state, action) => {
      state.brandDetail.status = 'failed'
      state.brandDetail.error = action.error.message
    },
    [addBrand.pending]: (state, action) => {
      state.addBrand.status = 'loading'
    },
    [addBrand.fulfilled]: (state, action) => {
      state.addBrand.status = 'succeeded'
      state.addBrand.data = action.payload
    },
    [addBrand.rejected]: (state, action) => {
      state.addBrand.status = 'failed'
      state.addBrand.error = action.error.message
    }
  }
})


export const { resetBrandsState } = brandsSlice.actions
export default brandsSlice.reducer