import Container from '../../components/Container'
import { useParams } from "react-router-dom"
import { showSnackbar, showLoader } from '../../app/actionReducers/utilsHelper'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductRequestDetail, changeProductReqStatus, changeVariantReqStatus, resetProductRequestsState } from '../../app/actionReducers/productRequests'
import { useEffect, useMemo } from 'react'
import { Box, ContentTable, CustomText, Divider, Row } from 'common-utils'
import moment from 'moment'
import { ProductRequestDetailStyle } from './index.style'
import { colorPallet } from 'common-utils/constants/colors'
import { Button } from '@mui/material'
import { useCallback } from 'react'

function ProductRequestDetail() {
  const { productReqId } = useParams()

  const productRequestDetail = useSelector(state =>
    state.productRequests.productRequestDetail
  )

  const changeProductReqStatusData = useSelector(state =>
    state.productRequests.changeProductReqStatus
  )

  const changeVariantReqStatusData = useSelector(state =>
    state.productRequests.changeVariantReqStatus
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProductRequestDetail({ productReqId }))
  }, [])

  useEffect(() => {
    if (productRequestDetail.status === 'failed' || productRequestDetail.status === 'succeeded') {
      if (productRequestDetail.status === 'failed') {
        dispatch(showSnackbar({
          message: productRequestDetail.error,
          severity: 'error'
        }))
      }
      dispatch(showLoader(false))
      dispatch(resetProductRequestsState('productRequestDetail'))
    }
    if (changeProductReqStatusData.status === 'failed' || changeProductReqStatusData.status === 'succeeded') {
      if (changeProductReqStatusData.status === 'failed') {
        dispatch(showSnackbar({
          message: changeProductReqStatusData.error,
          severity: 'error'
        }))
      }else{
        dispatch(fetchProductRequestDetail({ productReqId }))
        dispatch(showSnackbar({
          message: "Done!",
          severity: 'success'
        }))
      }
      dispatch(showLoader(false))
      dispatch(resetProductRequestsState('changeProductReqStatus'))
    }
    if (changeVariantReqStatusData.status === 'failed' || changeVariantReqStatusData.status === 'succeeded') {
      if (changeVariantReqStatusData.status === 'failed') {
        dispatch(showSnackbar({
          message: changeVariantReqStatusData.error,
          severity: 'error'
        }))
      }else{
        dispatch(fetchProductRequestDetail({ productReqId }))
        dispatch(showSnackbar({
          message: "Done!",
          severity: 'success'
        }))
      }
      dispatch(showLoader(false))
      dispatch(resetProductRequestsState('changeVariantReqStatus'))
    }
  }, [productRequestDetail, dispatch, changeProductReqStatusData, 
    productReqId, changeVariantReqStatusData
  ])

  const productReqData = useMemo(() => (
    productRequestDetail?.data?.data
  ), [productRequestDetail?.data?.data])

  const handleChangeProductReq = useCallback((type="accept")=>{
    if(type === 'accept'){
      dispatch(changeProductReqStatus({ productReqId, type: 'ACCEPT' }))
    }else{
      dispatch(changeProductReqStatus({ productReqId, type: 'REJECT' }))    
    }
  },[])

  const handleChangeVariantReq = (type,v)=>{
    if(type === 'accept'){
      dispatch(changeVariantReqStatus({ variantReqId: v, type: 'ACCEPT' }))
    }else{
      dispatch(changeVariantReqStatus({ variantReqId: v, type: 'REJECT' }))
    }
  }


  const productDataTable = useMemo(() => (
    [
      ...productReqData?.status === 'PENDING' ?
        [{
          key: '',
          val: (
            <Row justify="right">
              <Button sx={{ marginRight: '10px' }} variant='contained' color='primary'
                onClick={()=>handleChangeProductReq('accept')}
              >
                Accept
              </Button>
              <Button variant='outlined' color='error'
                onClick={()=>handleChangeProductReq('reject')}
              >
                Reject
              </Button>
            </Row>
          )
        }] : [],
      {
        key: 'Product Images:',
        val: <div>{
          productReqData?.imgUrl?.map((url, i) => (
            <a href={url} key={i} target='_blank'>
              <img height="150" width="150" src={url}></img>
            </a>
          ))
        }
        </div>
      },
      {
        key: 'Product Name:',
        val: productReqData?.productName
      }, {
        key: 'Product Req Id:',
        val: productReqData?.productReqId
      }, 
      {
        key: 'Brand Id:',
        val: productReqData?.brand?.brandId
      },
      {
        key: 'Brand Name:',
        val: productReqData?.brand?.brandName
      },
      {
        key: 'Category Id:',
        val: productReqData?.category?.categoryId
      },
      {
        key: 'Category Name:',
        val: productReqData?.category?.categoryName
      },
      {
        key: 'Supplier Id:',
        val: productReqData?.supplierId
      },
      {
        key: 'Added At:',
        val: moment(productReqData?.addedAt).format('DD MMMM YYYY')
      },
      {
        key: 'Updated At:',
        val: productReqData?.updatedAt ?
          moment(productReqData?.updatedAt).format('DD MMMM YYYY')
          :
          'Not Updated'
      },
      {
        key: 'Status:',
        val: productReqData?.status
      }

    ]
  ), [productReqData, handleChangeProductReq])

  return (
    <Container bgColor={colorPallet.lightGrey}>
      <ProductRequestDetailStyle>
        <ContentTable
          title="Product Request Detail"
          data={productDataTable}
        />
        {
          productReqData?.variantsData?.map((vd) => {
            const variantDataTable = [
              ...vd?.status === 'PENDING' ?
                [{
                  key: '',
                  val: (
                    <Row justify="right">
                      <Button sx={{ marginRight: '10px' }} 
                        variant='contained' 
                        color='primary' 
                        onClick={()=>handleChangeVariantReq('accept',vd.variantReqId)}
                      >
                        Accept
                      </Button>
                      <Button variant='outlined' color='error' 
                        onClick={()=>handleChangeVariantReq('reject',vd.variantReqId)}
                      >
                        Reject
                      </Button>
                    </Row>
                  )
                }] : [],
              {
                key: 'Variant Images:',
                val: <div>{
                  vd?.imgUrl?.map((url, i) => (
                    <a href={url} key={i} target='_blank'>
                      <img height="150" width="150" src={url}></img>
                    </a>
                  ))
                }
                </div>
              },
              {
                key: 'Variant Name:',
                val: vd?.variantName
              }, {
                key: 'Variant Req Id:',
                val: vd?.variantReqId
              },
              {
                key: 'Supplier Id:',
                val: vd?.supplierId
              },
              {
                key: 'Added At:',
                val: moment(vd?.addedAt).format('DD MMMM YYYY')
              },
              {
                key: 'Updated At:',
                val: vd?.updatedAt ?
                  moment(vd?.updatedAt).format('DD MMMM YYYY')
                  :
                  'Not Updated'
              },
              {
                key: 'Status:',
                val: vd?.status
              }
            ]
            return (
              <ContentTable
                key={vd.variantReqId}
                title="Variant Request Detail"
                data={variantDataTable}
              />
            )
          })
        }
      </ProductRequestDetailStyle>
    </Container>
  )
}

export default ProductRequestDetail