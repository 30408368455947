import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { getCookie } from '../../../utils/cookiesUtil'
import { parseError } from '../../../utils/errorParser'

export const fetchProductRequests = createAsyncThunk('fetchProductRequests',async (payload)=>{
  let url = `${process.env.REACT_APP_HOSTNAME}/get-product-req-list`
  if(payload.queryParams){
    url = `${url}${payload.queryParams}`
  }
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))
  }
})

export const fetchProductRequestDetail = createAsyncThunk('/fetchProductRequestDetail',async (payload)=>{
  const url = `${process.env.REACT_APP_HOSTNAME}/get-product-req-detail/${payload.productReqId}`
  const response = await fetch(url,{
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))  }
})

export const changeProductReqStatus = createAsyncThunk('/changeProductReqStatus',async (payload)=>{
  const url = `${process.env.REACT_APP_HOSTNAME}/change-product-req-status`
  const response = await fetch(url,{
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`,
      'Content-type':'application/json'
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))  }
})

export const changeVariantReqStatus = createAsyncThunk('/changeVariantReqStatus',async (payload)=>{
  const url = `${process.env.REACT_APP_HOSTNAME}/change-variant-req-status`
  const response = await fetch(url,{
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'authorization': `Bearer ${getCookie('authToken')}`,
      'Content-type':'application/json'
    }
  })
  if(response.ok){
    const json = await response.json()
    return json
  }else{
    const errorJson = await response.json()
    throw new Error(parseError(errorJson.error))  }
})

export const productRequestsSlice = createSlice({
  name:'productRequests',
  initialState:{
    productRequests:{
      data: [],
      status: 'idle',
      error: null
    },
    productRequestDetail:{
      data: {},
      status: 'idle',
      error: null
    },
    changeProductReqStatus: {
      data: {},
      status: 'idle',
      error: null
    },
    changeVariantReqStatus: {
      data: {},
      status: 'idle',
      error: null
    }
  },
  reducers:{
    resetProductRequestsState: (state,action)=>{
      state[action.payload]['status'] = 'idle'
    }
  },  
  extraReducers: {
    [fetchProductRequests.pending]: (state, action) => {
      state.productRequests.status = 'loading'
    },
    [fetchProductRequests.fulfilled]: (state, action) => {
      state.productRequests.status = 'succeeded'
      state.productRequests.data = action.payload
    },
    [fetchProductRequests.rejected]: (state, action) => {
      state.productRequests.status = 'failed'
      state.productRequests.error = action.error.message
    },
    [fetchProductRequestDetail.pending]: (state, action) => {
      state.productRequestDetail.status = 'loading'
    },
    [fetchProductRequestDetail.fulfilled]: (state, action) => {
      state.productRequestDetail.status = 'succeeded'
      state.productRequestDetail.data = action.payload
    },
    [fetchProductRequestDetail.rejected]: (state, action) => {
      state.productRequestDetail.status = 'failed'
      state.productRequestDetail.error = action.error.message
    },
    [changeProductReqStatus.pending]: (state, action) => {
      state.changeProductReqStatus.status = 'loading'
    },
    [changeProductReqStatus.fulfilled]: (state, action) => {
      state.changeProductReqStatus.status = 'succeeded'
      state.changeProductReqStatus.data = action.payload
    },
    [changeProductReqStatus.rejected]: (state, action) => {
      state.changeProductReqStatus.status = 'failed'
      state.changeProductReqStatus.error = action.error.message
    },
    [changeVariantReqStatus.pending]: (state, action) => {
      state.changeVariantReqStatus.status = 'loading'
    },
    [changeVariantReqStatus.fulfilled]: (state, action) => {
      state.changeVariantReqStatus.status = 'succeeded'
      state.changeVariantReqStatus.data = action.payload
    },
    [changeVariantReqStatus.rejected]: (state, action) => {
      state.changeVariantReqStatus.status = 'failed'
      state.changeVariantReqStatus.error = action.error.message
    }
  }
})

export const { resetProductRequestsState } = productRequestsSlice.actions

export default productRequestsSlice.reducer