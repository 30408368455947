import { getCookie } from "./cookiesUtil";

export const uploadFile = async (files)=>{
  const formData  = new FormData()

  files.forEach((file)=>{
    formData.append('file', file);
  })
  
  try{
    const response = await fetch(`${process.env.REACT_APP_HOSTNAME}/common/upload-image`,{
      method: 'POST',
      body: formData,
      headers: {
        'authorization': `Bearer ${getCookie('authToken')}`
      }
    })
    const json = await response.json()
    if(response.ok){
      return {
        success: true,
        data: json
      }
    }else{
      return {
        success: false,
        error: json.message
      }
    }
  }catch(err){
    return {
      success: false,
      error: err.message
    }
  }
}